.chat-container {
  width: 350px;
  height: 550px;
  border: 4px solid rgba(255,255,255,0.2);
  border-radius: 16px;
  // background: rgba(150,150,150,1);
  display: float;
  position: absolute;
  top: 10px;
  left: 10px;
  align-items: center;
  justify-content: center;
  .chat-wrap {
    width: 350px;
    max-width: 800px;
    height: 550px;
    background: rgba(238,238,238,1);
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    border: 2px solid rgba(0,0,0,0.5);
    box-shadow: 0 10px 20px rgba(0,0,0,0.5);
    box-sizing: border-box;
    padding: 10px 0;
    h2{
      height: 45px;
      line-height: 45px;
    }
    .chat-participants-container {
      display: flex;  
      justify-content: flex-start;
      width:200px;   
      margin-left: 9px;
      margin-top: 0px;
      margin-bottom: 5px;
      padding: 5px;
      flex-direction: column; 
      box-shadow: 3px 3px 8px rgba(0,0,0,0.05);
      border-right: 1px solid #ddd;
      border-bottom: 1px solid #ddd;
      box-sizing: border-box;
      .chat-participant {
        display: block;
        text-align: left;
        padding-left: 5px;
        // flex-direction: row;
        // justify-content: flex-start;
        width: 188px;
        font-size: smaller;
      }
    }
    .chat-message-wrap {
      flex-grow: 1;
      overflow-y: auto;
      border-bottom: 1px solid #333;
      background-color: #eee;
    }
    .chat-message-box {
      height: 100px;
      box-sizing: border-box;
      padding: 10px 5px;
      >textarea {
        padding: 5px 20px;
        height: 90px;
        width: 320px;
        resize: none;
        color: black;
        background-color: rgba(255,255,255,0.8);
        border: 1px solid #aaa;
      }
      >textarea::placeholder {
        color: #aaa;
      }
    }
  }
  .chat-disabled {
    height: 155px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    flex-shrink: 0;
  }
}